<template>
    <div class="wrapper">
        <div class="nav flex">
            <div
                class="nav-item flex ai-c jc-c"
                v-for="(item, index) in nav"
                :class="active == index && 'active'"
                :key="index"
                @click="navClick(index)"
            >
                {{ item.name }}
                <!-- ({{ item.nb }}) -->
            </div>
        </div>
        <template v-if="empty">
            <v-empty type="coupon" />
            <v-loveGoodsList />
        </template>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad" 
            class="list flex fd-c ai-c">
            <template v-for="(item, index) in list">
                <div style="margin-bottom: 0.27rem;" :key="index">
                    <div
                        class="item flex ai-c"
                        :style="{
                            'background-image':
                                'url(/img/coupons/' + active + '.png)',
                        }"
                    >
                        <div class="money flex fd-c ai-c jc-c" v-if="item.discountType==0">
                            <div class="t1" :style="String(item.amount).length>4?'font-size: 0.42rem':''">{{item.amount}}</div>
                            <div class="t2" v-if="item.minPoint!=0">满{{item.minPoint}}使用</div>
                            <div class="t2" v-else>无门槛</div>
                        </div>
                        <div class="money flex fd-c ai-c jc-c" v-if="item.discountType==1">
                            <div class="t3">{{item.proportion*10}}折</div>
                            <div class="t2" v-if="item.minPoint!=0">满{{item.minPoint}}使用</div>
                            <div class="t2" v-else>无门槛</div>
                        </div>
                        <div class="item-r">
                            <div
                                class="name lines"
                                :style="active != 0 && 'color: #999999;'"
                            >
                                {{item.name}}
                            </div>
                            <div class="time">有效期至：{{item.endTime.substring(0,10)}}</div>
                        </div>
                        <div v-if="active == 0" @click="Click(item)" class="btn flex ai-c jc-c">
                            去使用
                        </div>
                        <div class="remarks-btn" @click="item.isShow=!item.isShow">
                            使用说明
                            <img :style="item.isShow||'transform:rotate(180deg)'" src="/img/more1.png" alt="">
                        </div>
                    </div>
                    <div class="rule" v-show="item.isShow" v-html="item.note">
                    </div>
                </div>
            </template>
        </van-list>
        <div class="kong"></div>
        <div class="go-home flex ai-c jc-c">
          <div class="btn" @click="$router.push('/home')">返回商城</div>
      </div>
    </div>
</template>

<script>
import { couponList } from "../../../api";
import loveGoodsList from "@/components/loveGoodsList";
export default {
    components: {
        "v-loveGoodsList": loveGoodsList,
    },
    props: {},
    data() {
        return {
            nav: [
                {
                    name: "未使用",
                    nb: 3,
                },
                {
                    name: "已使用",
                    nb: 2,
                },
                {
                    name: "已过期",
                    nb: 1,
                },
            ],
            active: 0,
            empty: false,
            list: [],
            loading: false,
            finished: false,
            current: 1
        };
    },
    watch: {},
    computed: {},
    methods: {
        navClick(index) {
            this.active = index
            this.current = 1
            this.list = []
            this.couponList()
        },
        couponList() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true
            })
            couponList({
                useStatus: this.active,
                current: this.current,
                size: 20,
            }).then((res) => {
                this.current++
                var data = res.data.data.records
                this.loading = false;
                if (data.length == 0) {
                    this.finished = true;
                } else {
                    this.finished = false;
                }
                for (var item of data) {
                    item.note = item.note.replace(/\n/g,'<br>')
                    item.isShow = false
                }
                this.list = this.list.concat(data)
                if (this.list.length==0) {
                    this.empty = true
                } else {
                    this.empty = false
                }
            }).finally(()=>{
                this.$toast.clear()
            });
        },
        onLoad() {
            this.couponList()
        },
        Click(item){
            if (item.useType==0) {
                this.$router.push({path: '/home'})
            } else {
                this.$router.push({path: '/my/coupons/goodsList',query: {id: item.couponId}})
            }
        }
    },
    created() {
    },
    mounted() {},
};
</script>
<style scoped>
.wrapper {
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    background-color: rgba(238, 238, 238, 1);
}
.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: #fff;
    z-index: 100;
}
.nav-item {
    position: relative;
    flex: 1;
    height: 100%;
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #353535;
}
.active {
    font-size: 0.3rem;
    font-family: PingFang;
    font-weight: bold;
    color: #f35e3a;
}
.active::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 0.55rem);
    width: 1.1rem;
    height: 0.04rem;
    background: #f35e3a;
    border-radius: 0.02rem;
}
.list {
    width: 100%;
    margin-top: 1.24rem;
}
.item {
    position: relative;
    width: 6.9rem;
    height: 1.73rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.money {
    width: 1.98rem;
    height: 1.73rem;
}
.money .t1 {
    font-size: 0.52rem;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
}
.money .t3 {
    font-size: 0.52rem;
    font-family: PingFang;
    font-weight: bold;
    color: #ffffff;
}
.money .t1::before {
    content: "¥";
    font-size: 0.34rem;
}
.money .t2 {
    font-size: 0.22rem;
    font-family: PingFang;
    font-weight: 500;
    color: #ffffff;
}
.item-r {
    height: 100%;
    margin-left: 0.18rem;
}
.name {
    width: 3.3rem;
    line-height: .38rem;
    height: .76rem;
    font-size: 0.28rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    margin-top: .3rem;
}
.time {
    font-size: 0.2rem;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    margin-top: .15rem;
}
.btn {
    position: absolute;
    right: 0.2rem;
    top: 0.25rem;
    width: 1rem;
    height: 0.4rem;
    border: 1px solid #f35e3a;
    border-radius: 0.2rem;
    font-size: 0.24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #f35e3a;
}
.remarks-btn{
    position: absolute;
    bottom: .1rem;
    right: .1rem;
    font-size: .20rem;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
}
.remarks-btn img{
    width: .18rem;
    height: .18rem;
}
.rule{
    width: 6.73rem;
    margin-left: calc(50% - 3.36rem);
    box-sizing: border-box;
    background: #F5F5F5;
    margin-top: -.2rem;
    padding: .4rem .4rem .2rem;
    box-shadow: 0rem 1px .07rem 0rem rgba(169, 169, 169, 0.18);
    font-size: .20rem;
    font-family: PingFang;
    font-weight: 500;
    color: #969595;
}
.go-home{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.4rem;
    background-color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.go-home .btn{
    width: 6.90rem;
    height: .90rem;
    background: #F35E3A;
    border-radius: .45rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: .9rem;
}
.kong{
    width: 100%;
    height: 2rem;
}
</style>